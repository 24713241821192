import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import theme from '../../config/theme'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'

const About = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout color={theme.colors.primary}>
      <SEO />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[3, 3, 4, 3, 4, 6]}>
        <div>
          <h1 style={{ color: theme.colors.primary }}>Bhargavi Venkataraman</h1>
          <p>
            Bhargavi Venkataraman, an engineer by profession began her nrithya journey as a third grader. Bhargavi's
            dance brings a fresh grace and beauty. She holds her Masters of Fine Arts in Bharatanatyam from SASTRA
            University, India, and is also a{' '}
            <span style={{ color: theme.colors.secondary }}>Grade B Doordarshan artist</span>. Maturing into a fine solo
            performer, Bhargavi has had the opportunity to perform at various prestigious{' '}
            <span style={{ color: theme.colors.secondary }}>arangams</span>, premier dance festivals and prominent
            sabhas in India. She was awarded the{' '}
            <span style={{ color: theme.colors.secondary }}>Best performer of the year 2016</span> by the{' '}
            <span style={{ color: theme.colors.secondary }}>Sri Parthasarathy Swami Sabha</span>. Bhargavi was one of
            the most privileged 1000 dancers to perform on the 1000<sup>th</sup> year celebration of the Tanjore
            Brihadeeswarar Temple.
          </p>
          <p>
            Grace came early to Bhargavi's dance. Even at the age of ten, Bhargavi captivated audiences during stage
            performances with neat footwork and beauty of abhinaya. Bhargavi strikes fine balances, she melds taut
            Bharatanatyam stances with a lissome eloquence, transforming herself into various personae into navarasas.
            Without a cloying overstatement, she connects to her audience with shraddha to the art form.
          </p>
          <p>
            Today, Bhargavi has equipped herself in most areas of Bharatanatyam, mainly nattuvangum, music and teaching,
            while her focus is on learning more. Bhargavi is dedicated to her guru Smt. Bhagyashree Satthish and finds
            inspiration in her. Mentored by her guru, Bhargavi has taught several students in varying ages ranging from
            4 to 75. As she continues teaching students with the values passed on by her teacher, she looks forward to
            moulding budding artists with an amalgamation of traditional and innovative techniques. Bhargavi has been a
            prompt nattuvanar every Margazhi season apart from the arangetrams and other recitals throughout the year.
            She has had the privilege to be on the nattuvangam while her guru performed for the season at the Bhavan's
            Tag auditorium in the year 2017. For Bhargavi, there has always been great emphasis on physical fitness,
            constant learning and an overall well-being even with her students. Learning and growing has been a constant
            and the foundation of her journey. She has co-ordinated with her guru in conducting various workshops at the
            dance institue every year. Focussing on flexibility and body fitness, she trains a lot of students for their
            dance shows, arangetram, being the major one.
          </p>
          <p>
            Heartfelt Bhava and energetic Nritta find ample expression in her nrithya. Good rhythmic commmand, great
            felicity of expression and pleasant stage presence are her forte. With her supple and agile form, filled
            with youthful energy, Bhargavi makes Bharatanatyam, a pleasure to watch.
          </p>
          <h2 style={{ color: theme.colors.primary }}>Notable Performances</h2>
          <ul>
            <li>
              Participated in the <span style={{ color: theme.colors.secondary }}>Guinness World Record</span> event for
              the largest Bharatanatyam dance lesson.
            </li>
            <li>
              Performed in the <span style={{ color: theme.colors.secondary }}>Nitya Akhanda Nrittam</span> as a part of
              the event for Asia Book of Records.
            </li>
            <li>
              <span style={{ color: theme.colors.secondary }}>Moovarnam</span> - depiction of the significance of the
              Indian Tricolor at various arangams in Chennai, India.
            </li>
            <li>
              <span style={{ color: theme.colors.secondary }}>Rajarajeshwaram</span> - 1000<sup>th</sup> year
              celebration of the Tanjore Brihadeeswarar Temple.
            </li>
            <li>
              Performances organized by <span style={{ color: theme.colors.secondary }}>Natyanjali</span> around
              Tamilnadu, India (Chindabaram, Thiruvarur, Tanjore, Nagapattinam, Kumbakonam, Thirunallar, Mayuram).
            </li>
            <li>
              Indian dance festival organized by <span style={{ color: theme.colors.secondary }}>India Tourism</span>,
              Mamallapuram.
            </li>
            <li>
              Dance festival organized by <span style={{ color: theme.colors.secondary }}>Bhilai Tamil Manram</span>
            </li>
            <li>
              Major Sabhas including{' '}
              <span style={{ color: theme.colors.secondary }}>Sri Parthasarathy Swami Sabha</span>,{' '}
              <span style={{ color: theme.colors.secondary }}>Bharat Kalachar</span>,{' '}
              <span style={{ color: theme.colors.secondary }}>Vipanchee</span>, and several other dance festivals in
              Chennai, India.
            </li>
            <li>
              Performed at the <span style={{ color: theme.colors.secondary }}>Devasthanams of Tirumala Tirupathi</span>
              , <span style={{ color: theme.colors.secondary }}>Chotaanikara Bhagavathi</span>,{' '}
              <span style={{ color: theme.colors.secondary }}>Trivandrum Sri Anantha Padmanabha Swamy Temple</span>.
            </li>
            <li>
              Played significant roles for in-house productions of{' '}
              <span style={{ color: theme.colors.secondary }}>Om Nrithyakshethra</span> (Shiva Shakthi Thandavam,
              Panchavadivum, Om Namo Narayana, Panchabhoota Sthalam, Vanavil).
            </li>
          </ul>
        </div>
      </AnimatedBox>
    </Layout>
  )
}

export default About
